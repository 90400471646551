import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { AppProviderInterface } from '@shared/interfaces/app-provider.interface'
import { AppContext } from '@providers/app-provider/_contexts'
import { GRAPHIC_TEXT_LOGO, ONE_SECOND_MS } from '@root/constants'
import { useAppDispatch } from '@root/store'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import { ContentEntity } from '@apis/contents-microservice/_entities/_types'
import { DARK_FALLBACK, FONT_FALLBACK } from '@shared/constants/theme/colours.constants'
import { selectContentByIdV2, selectContentResourcesById } from '@store/contents-slice/_selectors'
import { useSelector } from 'react-redux'
import { DialogProvider } from '@components/dialog-provider'
import { ContentItem } from '@store/cart-slice/_interfaces'
import { addToCart } from '@store/cart-slice'
import { useRouter } from 'next/router'
import useBoughtPath from '@hooks/_content/bundle/use-bought-path'
import { isEmptyString } from '@utils/strings'
import { ContentLayout } from '@shared/enums/content/content-layout.enum'
import usePageWidth from '@hooks/layout/use-page-width'
import { LAPTOP_MM_BREAKPOINT } from '@shared/constants/layout/constants'

export interface Props {
  categoryQuery?: string
  children: ReactNode
  currentPage?: string
  word?: string
  refererPage?: string
  channel?: ChannelInterface
  content?: ContentEntity
  // Used on player/bundle
  autoplay?: string
  seconds?: number
  contentBundleId?: string
  moduleId?: string
}

export const AppProvider = ({
  children,
  word,
  currentPage,
  categoryQuery,
  refererPage,
  channel,
  content,
  autoplay,
  moduleId,
  seconds,
  contentBundleId,
}: Props) => {
  const dispatch = useAppDispatch()
  const router = useRouter()

  // SSR doesn't like destructure variables
  const contentId = content?.contentId

  // States
  const [searchQuery, setSearchQuery] = useState<string>(word ?? '')
  const [category, setCategory] = useState<string>(categoryQuery ?? '')
  const [currentPath, setCurrentPath] = useState<string>(currentPage ?? '/')
  // We aren't using the referer at the moment, but I think is good idea to keep it.
  const [referer, setReferer] = useState<string>(refererPage ?? '/')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isContentLoading, setIsContentLoading] = useState<boolean>(true)
  const [contentData, setContentData] = useState<ContentEntity>(content)
  // This size is in pixels
  const [bundleBoxMobileSizePx, setBundleBoxMobileSizePx] = useState<number>(300)
  const [playerSize, setPlayerSize] = useState<number>(300)
  const [playerTopDistance, setPlayerTopDistance] = useState<number>(300)

  // White label
  const { whiteLabel = undefined } = channel?.organisation || {}

  // Some old components are still working with this variables
  const channelUrl = channel?.channelUrl ?? ''

  // Theme variables
  const hasWhiteLabel = !!whiteLabel
  const channelTheme = channel?.channelTheme
  const logoToUse = whiteLabel?.logoUrl ?? GRAPHIC_TEXT_LOGO
  const buttonColour = channelTheme?.buttonColour ?? FONT_FALLBACK
  const fontColour = channelTheme?.buttonFontColour ?? DARK_FALLBACK

  // Layout
  const isMobileStyle = usePageWidth(LAPTOP_MM_BREAKPOINT)

  const loadingContentInterval = useRef(null)

  // For now, I'm getting this information from the old thunk
  const contentResources = useSelector(selectContentResourcesById(contentId))

  // Getting content  information in redux.
  const _content = useSelector(selectContentByIdV2(contentId))

  const boughtBundlePath = useBoughtPath(contentId, channelUrl)

  // Handlers
  const handleOnSearch = (query: string) => setSearchQuery(query)
  const handleCategory = (category: string) => setCategory(category)
  const handleReferer = (referer: string) => setReferer(referer)
  const handleCurrentPath = (path: string) => setCurrentPath(path)
  const toggleIsLoading = (loading: boolean) => setIsLoading(loading)

  /**
   * Handle add content to the cart
   * @param price
   */
  const handleAddToCart = (price: number) => {
    // Dispatch action to
    const addToCard: ContentItem = {
      contentId,
      label: contentData.title,
      price: price,
    }
    dispatch(addToCart(addToCard))
  } // End add to cart

  useMemo(() => {
    const handleClearLoadingInterval = () => clearInterval(loadingContentInterval.current)

    if (!!_content) {
      handleClearLoadingInterval()
      loadingContentInterval.current = setTimeout(() => {
        const buttonState = _content?.state?.button ?? 'buyNow'
        // Only on bought bundles
        if (
          _content?.contentType === 'bundle' &&
          buttonState === 'purchased' &&
          _content?.contentLayoutStyle === ContentLayout.MODERN &&
          !isEmptyString(boughtBundlePath)
        ) {
          router.push(boughtBundlePath)
        } else {
          setContentData(_content)
          setIsContentLoading(false)
        }
      }, ONE_SECOND_MS)
    }
  }, [_content, boughtBundlePath, router])

  useEffect(() => {
    setCurrentPath(currentPage ?? '/')
  }, [currentPage])

  // Context
  const appContext: AppProviderInterface = {
    channel,
    channelUrl,

    // Content data
    content: contentData,
    contentId: contentData?.contentId ?? '',
    contentResources,
    isContentLoading,

    // Search params
    searchQuery,
    category,
    currentPath,
    referer,
    isLoading,
    handleOnSearch,
    handleCurrentPath,
    toggleIsLoading,
    handleCategory,
    handleReferer,

    // Theme / whitelabel data
    whiteLabel,
    hasWhiteLabel,
    logoToUse,
    buttonColour,
    fontColour,
    channelTheme,

    // Layout
    contentLayoutStyle: content?.contentLayoutStyle ?? ContentLayout.LEGACY,
    isModernLayout: content?.contentLayoutStyle === ContentLayout.MODERN,
    isMobileStyle,

    // Add to content
    handleAddToCart,

    // Player variables
    autoplay,
    seconds,

    // Bundle information
    moduleId,
    contentBundleId,

    // Player sizes
    playerSize,
    setPlayerSize,
    playerTopDistance,
    setPlayerTopDistance,
    bundleBoxMobileSizePx,
    setBundleBoxMobileSizePx,
  }

  return (
    <AppContext.Provider value={appContext}>
      <DialogProvider>{children}</DialogProvider>
    </AppContext.Provider>
  )
}
